@import '_variables.scss';
a[href] {
    &::after {
        content: none !important;
    }
}
#header {
    height: auto;
    .header-container {
        position: relative;
    }
}

.widget-feed-detail {
    > .grid-x > .cell {
        width: calc(100% - #{$mediumGutters * 2});
    }
}

#sc8RK1 {
    display: none;
}